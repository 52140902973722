import React from 'react'
import Logo from "../../assets/logo.png";
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
    TruckIcon
} from "@heroicons/react/outline";
import css from './Footer.module.css'


const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
        <hr/>

        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt="" />
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon} />
                        <span> 123 Market St Portland, OR 97201</span>
                    </span>
                <span className={css.pngLine}>
                    {" "}
                    <PhoneIcon className={css.icon} />
                    <a href="tel:971-801-0823">971-801-0823</a>
                </span>
                <span className={css.pngLine}>
                    {" "}
                    <InboxIcon className={css.icon} />
                    <a href="mailto:support@jiathelabel.com">support@jiathelabel.com</a>
                </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account</span>
                    <span className={css.pngLine}>
                        <LoginIcon className= {css.icon} />
                        Sign In
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                        <UsersIcon className= {css.icon}/>
                        <a href="/about">
                            <p> About Us</p>
                        </a>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon} />
                        <p>Privacy & Terms</p>
                    </span>
                    <span className={css.pngLine}>
                        <TruckIcon className={css.icon} />
                        Shipping
                    </span>
                </div>
            </div>
        </div>
        <div className={css.copyRight}>
                <span>Copyright 2022 by JiaTheLabel, Inc.</span>
                <span>All rights reserved.</span>
            </div>
    </div>
  )
}

export default Footer