import profile1 from '../assets/profile1.png'
import profile2 from '../assets/profile2.png'

export const TestimonialsData = [
    {
        image : profile1,
        comment: "super fast shipping and just what I wanted! thank you!",
        name: "Emma Lee",
        stars: 5
    },
    {
        image : profile2,
        comment: "super fast shipping & nice seller! thank you!!",
        name: "Sophia Rose",
        stars: 5
    },
    {
        image : profile1,
        comment: "Nice seller! Left a sweet note :)",
        name: "Nancy Leung",
        stars: 5
    },
    {
        image : profile1,
        comment: "The skirt came so fast and is just as described! Thanks so much I love it :)",
        name: "Elyssa Seltzer",
        stars: 5
    },
    {
        image : profile2,
        comment: "seller was communicative, shipped quickly and item arrived in perfect condition! thank you!! <3",
        name: "Katherine L",
        stars: 5
    },
    {
        image : profile1,
        comment: "amazing shipped so fast and super sweet note in the package",
        name: "Kara Rog",
        stars: 5
    }
]