import React from 'react'
import css from './Header.module.css'
import Logo from '../../assets/logo.png'
import {CgShoppingBag} from 'react-icons/cg'
const header = () => {
  return (
    <div className={css.container}>
        <div className={css.logo}>
            <img src={Logo} alt="" />
        </div>
    
        <div className={css.right}>
            <div className={css.menu}>
                <ul className={css.menu}>
                    <li>Home</li>
                    <li>Catalog</li>
                    <li>Contact</li>
                </ul>
            </div>


            <input type="text" className={css.search} placeholder="Search" />

            <CgShoppingBag className={css.cart}/>
        </div>
    </div>
        
  )
}

export default header