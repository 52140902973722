import React from 'react'
import css from './VisProc.module.css'
import OurVision from '../../assets/OurVision.png'
import TheProcess from '../../assets/TheProcess.png'
const VisProc = () => {
  return (
    <div className={css.container1}>
        <div className={css.ourvision}>
            <img src={OurVision} alt="" />
        </div>
        <p className= "learn-text">
            *Learn more about sustainability in fashion as well as fighting child labor practices at 
            <a href="https://www.genevaenvironmentnetwork.org/resources/updates/sustainable-fashion/" style={{color: '#38b6ff'}}> genevaenvironmentnetwork.org</a> and
            <a href="https://labs.theguardian.com/unicef-child-labour/"  style={{color: '#38b6ff'}}> labs.theguardian.com/unicef-child-labour</a>.
        </p>
        <div className={css.ourprocess}>
            <img src={TheProcess} alt="" />
        </div>

    </div>
  )
}

export default VisProc