import img1 from '../assets/img1.png'
import img2 from '../assets/img2.png'
import img3 from '../assets/img3.png'


export const SliderProducts = [{
    name: 'TOP',
    detail: 'Sofia Top',
    price: '35',
    img: img1
},
{
    name: 'TOP',
    detail: 'Claudia Top',
    price: '40',
    img: img2
},
{
    name: 'BOTTOM',
    detail: 'Belle Skirt',
    price: '35',
    img: img3
}
]

