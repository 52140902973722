import React from 'react';
import { TestimonialsData } from '../../data/testimonials';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StarIcon } from '@heroicons/react/outline';
import css from './Testimonials.module.css';

const Testimonials = () => {
  return (
    <div className={css.reviews} style={{ display: "block", marginTop: "60px", textAlign: "center" }}>
      <span style={{ fontWeight: "bold", fontSize: "30px" }}>REVIEWS</span>
      <div className={css.carousel}>
        <Swiper
          slidesPerView={3}
          slidesPerGroup={1}
          spaceBetween={20}
          className={css.tCarousel}
          breakpoints={{
            856: {
              slidesPerView: 3
            },
            640: {
              slidesPerView: 2
            },
            0: {
              slidesPerView: 1
            }
          }}
        >
          {
            TestimonialsData.map((testimonial, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className={css.testimonial}>
                    <img src={testimonial.image} alt="" />
                    <div className={css.stars}>
                      {
                        [...Array(5)].map((_,i)=> (
                          <StarIcon fill="yellow" className={css.icon} key={i} />
                        ))
                      }
                    </div>
                    <span>{testimonial.comment}</span>
                    <hr />
                    <span>{testimonial.name}</span>
                  </div>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </div>
      <div className={css.deals}>
        <span style={{fontWeight: "bold", fontSize: "15px"}}> Sign up for exclusive deals   </span>
                      <input type="email" placeholder="Enter your email address" />
                      <button>Sign Up</button>
      </div>
      <div className={css.statement}>
        <p> *Note: This is simply a mock website based on my actual seller profile on Depop, a popular marketplace platform. Additionally, the reviews and products are real data extracted from my Depop.  
          </p>
      </div>
    </div>
  );
};

export default Testimonials;