import React from 'react'
import css from './About.module.css'
const About = () => {
  return (
    <div style = {{backgroundColor:"black"}}>
    <div className={css.container} style={{color:"white"}}>
    <div className= {css.middle}>
        <span>nice to meet you</span>
        </div>
        <div className={css.text1}>
            <p style={{ border: '0.5px solid black' }}>
                <span> Jia The Label is a small, independently owned and operated clothing business based in Portland, Oregon. During the pandemic, I found a passion
                    for sewing and have since been creating custom clothing. What started as a way to pass the time and find some creative fulfillment has turned into 
                    a true love for design as it allows me to express myself through art. What initially sparked my interest was an encounter with an older woman who, 
                    despite no longer being able to sew herself, wanted to see her beloved fabrics put to good use. She generously gifted me with her thread and fabric, 
                    and it was with these materials that I first learned the art of sewing. From that moment on, I committed to fulfilling her legacy and representing 
                    the small community of young seamstresses. As a result, I have been able to bring my fashion ideas to life in a way that feels both challenging and 
                    rewarding. I am constantly learning and growing, and I am so grateful to have found joy in sewing during such difficult and unprecedented times. 
                </span>
                <p>
                    Born in China and raised in the U.S., I understand the importance of <span style={{ fontWeight: 'bold' }}>inclusivity</span>.
                    Jia The Label is dedicated to providing high-quality, custom clothing for all of our clients, no matter their style or size. 
                    Our roots may be in China, but we are proud to call Portland home and be a part of the creative community here. We seek to 
                    continuously improve and evolve our craft. Thank you for supporting us on this journey.
                    </p>
                <p style={{ textAlign: 'right'}}>
            -McKenzie Bourn, 21 
            <br />
            <span style={{ fontStyle: 'italic' }}>Student/Founder</span>
                </p>
            </p>  
        </div>
       
    </div>
    </div>
  )
}

export default About